import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import ReactMarkdown from "react-markdown";

const CaseStudy: React.FC = () => {
    const caseStudies = useStaticQuery(query).data.edges;
    const [slideIndex, setSlideIndex] = useState(0);
    const nextSlide = () => {
        let nextSlide = slideIndex + 1;
        if (nextSlide === caseStudies.length) nextSlide = 0;
        setSlideIndex(nextSlide);
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const { dir } = eventData;
            if (dir === "Left") {
                let newSlide = slideIndex + 1;
                if (newSlide > caseStudies.length - 1) newSlide = 0;
                setSlideIndex(newSlide)
            }
            else if (dir === "Right") {
                let newSlide = slideIndex - 1;
                if (newSlide === - 1) newSlide = caseStudies.length - 1;
                setSlideIndex(newSlide)
            }
        }
    });

    return (
        <>
            {/* Desktop */}
            <div className="hidden lg:flex place-content-center w-[820px] xl:w-[960px] 2xl:w-[1100px] m-auto mb-14">
                <div className="rounded-l-3xl bg-white flex flex-col w-1/2 p-9 xl:p-11 2xl:p-12 min-h-[600px]">
                    <div className="">
                        <div className="mb-6 text-lg leading-tight w-[80%] font-semibold font-sans">{caseStudies[slideIndex].node.title}</div>
                        <ReactMarkdown className="w-[80%] text-sm font-sans">{caseStudies[slideIndex].node.body.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                    </div>
                    <div className="grow h-0"></div>
                    <div className="w-full border-t border-t-gray-light flex pt-3">
                        <div className="flex space-x-1 pt-2">
                            {
                                caseStudies.map((item: any, i: number) => {
                                    return (
                                        <div key={item.node.title} className={`rounded-full h-2 w-2 border ${slideIndex === i ? "bg-orange border-orange" : "bg-white border-gray-light"}`}></div>
                                    )
                                })
                            }
                        </div>
                        <div className="grow h-0"></div>
                        <div className="font-bold hover:cursor-pointer font-sans" onClick={nextSlide}>Next</div>
                    </div>
                </div>
                <div className="w-1/2 min-h-[600px]">
                    <GatsbyImage loading="eager" image={getImage(caseStudies[slideIndex].node.thumbnail.localFile.childImageSharp)} objectFit="cover" alt="" imgStyle={{borderRadius: "0 1.5rem 1.5rem 0"}}></GatsbyImage>
                </div>
            </div>

            {/* Mobile */}
            <div className="lg:hidden flex flex-col m-auto mb-14 px-3 max-w-[440px]" {...handlers}>
                <div className="h-[240px]">
                    <GatsbyImage loading="eager" image={getImage(caseStudies[slideIndex].node.squareThumbnail.localFile.childImageSharp)} objectPosition="50% 15%" alt="" imgStyle={{borderRadius: "1.5rem 1.5rem 0 0"}}></GatsbyImage>
                </div>
                <div className="rounded-b-3xl bg-white flex flex-col pb-4 px-4 xs:px-5 xs:pb-5 min-h-[480px]">
                    <div className="flex flex-col">
                        <div className="text-base font-bold font-sans my-3">{caseStudies[slideIndex].node.title}</div>
                        <ReactMarkdown className="font-sans text-sm">{caseStudies[slideIndex].node.body.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                    </div>
                    <div className="grow h-0"></div>
                    <div className="w-full flex space-x-1 mt-6">
                        <div className="grow h-0"></div>
                        {
                            caseStudies.map((item: any, i: number) => {
                                return (
                                    <div key={item.node.title} className={`rounded-full h-2 w-2 border mx-0 m-auto ${slideIndex === i ? "bg-orange border-orange" : "bg-white border-gray-light"}`}></div>
                                )
                            })
                        }
                        <div className="grow h-0"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseStudy;

export const query = graphql`
  query CaseStudy {
    data: allStrapiNorthstarCaseStudy(sort: {fields: order}) {
        edges {
          node {
            body {
              data {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            squareThumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            title
          }
        }
    }
  }
`;