import React from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../../components/HeroBanner";
import MenuBar from "../../components/MenuBar";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import PageContent from "../../components/PageContent";
import SectionHeader from "../../components/SectionHeader";
import Footer from "../../components/Footer";
import clouds from "../../assets/northstar/clouds2.png";
import dividerTop from "../../assets/northstar/divider_top.svg"
import dividerBottom from "../../assets/northstar/divider_bottom.svg"

import benchmark from "../../assets/northstar/cc/benchmark.navy.png"
import discover from "../../assets/northstar/cc/discover.navy.png"
import integrate from "../../assets/northstar/cc/integrate.navy.png"
import optimize from "../../assets/northstar/cc/optimize.navy.png"
import CaseStudy from "../../components/CaseStudy";
import ReactMarkdown from "react-markdown";


type DataScienceCardProps = {
  title: string;
  desc: string;
  icon: any;
  index: number;
};
const DataScienceCard: React.FC<DataScienceCardProps> = ({ title, desc, icon, index }) => {
  return (
    <div className={`w-full flex justify-center ${index % 2 === 0 ? "lg:justify-end" : "lg:justify-start"}`}>
      <div className="max-w-[450px]">
        <div className="flex items-end justify-between">
          <img
            src={icon}
            alt={title}
            className="object-contain h-16 lg:h-24"
          />
          <div className="font-sans font-semibold text-lg pr-4">
            {title}
          </div>
        </div>
        <div className="h-[1px] bg-gray-light w-full my-4"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2">
          <div></div>
          <p className="text-sm text-gray-dark">{desc}</p>
        </div>
      </div>
    </div>
  );
};

const AboutPage: React.FC = () => {
  const queryResults = useStaticQuery(query);

  const aboutPageContent = queryResults?.aboutPage?.edges[0]?.node;
  const dataScienceCards = queryResults?.dataScienceCards.edges;

  const heroImage = getImage(aboutPageContent.heroImage?.localFile?.childImageSharp);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Northstar - About" />
        <title>Northstar - About</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar altLogo={true} />

        <HeroBanner
          image={heroImage} title={aboutPageContent.heroTitle.data.childMarkdownRemark.rawMarkdownBody}
          description={aboutPageContent.heroDescription.data.childMarkdownRemark.rawMarkdownBody}
          altTextColor={true}
          northstarButton={true}
          objectPositionMobile={"bottom"} />
        {/* <div className="relative">
          <img src={clouds} className="absolute w-[100vw] top-[-90px] sm:top-[-150px] md:top-[-200px] lg:top-[-250px] xl:top-[-300px] 2xl:top-[-350px] 3xl:top-[-400px]"></img>
        </div> */}
        <PageContent>
          <SectionHeader
            centered
            title={aboutPageContent.howWeHelpTitle}
            description={aboutPageContent.howWeHelpDescription.data.childMarkdownRemark.rawMarkdownBody}
            descriptionClassName="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
          />
          <div className="flex justify-center">
            <div className="bg-white rounded-3xl p-2 xl:p-10 grid lg:grid-cols-3 gap-6 justify-center">
              <div className="bg-blue rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                <div className="p-6 xl:p-10">
                  <div className="text-xl lg:text-2xl text-white font-title-mobile leading-none mb-6">{aboutPageContent.howWeHelpLeftColumnTitle.data.childMarkdownRemark.rawMarkdownBody}</div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpLeftColumnItemOne.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpLeftColumnItemTwo.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpLeftColumnItemThree.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                </div>
              </div>
              <div className="bg-off-white rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                <div className="p-6 xl:p-10">
                  <div className="text-xl lg:text-2xl font-title-mobile leading-none mb-6">{aboutPageContent.howWeHelpMiddleColumnTitle.data.childMarkdownRemark.rawMarkdownBody}</div>
                  <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold">{aboutPageContent.howWeHelpMiddleColumnItemOne.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold">{aboutPageContent.howWeHelpMiddleColumnItemTwo.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b border-b-gray-light border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold">{aboutPageContent.howWeHelpMiddleColumnItemThree.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                </div>
              </div>
              <div className="bg-orange rounded-3xl text-center max-w-[350px] lg:max-w-[420px]">
                <div className="p-6 xl:p-10">
                  <div className="text-xl lg:text-2xl text-white font-title-mobile leading-none mb-6">{aboutPageContent.howWeHelpRightColumnTitle.data.childMarkdownRemark.rawMarkdownBody}</div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpRightColumnItemOne.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpRightColumnItemTwo.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                  <div className="border-b-gray-light border-b border-opacity-20 flex py-5 items-center">
                    <ReactMarkdown className="text-left font-semibold text-white">{aboutPageContent.howWeHelpRightColumnItemThree.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageContent>
        <PageContent padding="px-0">
          <img src={dividerTop} className="w-[100vw]"></img>
          <div className="bg-blue text-white px-8 py-14">
            {/* <SectionHeader
              title={aboutPageContent.platformInfoTitle.data.childMarkdownRemark.rawMarkdownBody}
              description={aboutPageContent.platformInfoDescription.data.childMarkdownRemark.rawMarkdownBody}
              // className="text-3xl lg:text-6xl md:text-3xl"
              descriptionClassName="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
            /> */}
            <div className="w-full mb-12 flex flex-col items-center">
              <ReactMarkdown
                children={aboutPageContent.platformInfoTitle.data.childMarkdownRemark.rawMarkdownBody}
                className="text-center lg:font-title font-title-mobile leading-none text-3xl lg:text-6xl md:text-3xl"
              />
              <h3
                className="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
              >{aboutPageContent.platformInfoDescription.data.childMarkdownRemark.rawMarkdownBody}</h3>
            </div>
            <div className="lg:hidden">
              <div className="mb-20 flex flex-col items-center">
                <div className="p-12 md:p-16 bg-[#2F5B92] rounded-3xl mb-8 ">
                  <GatsbyImage image={aboutPageContent.platformInfoCardOneImage.localFile.childImageSharp.gatsbyImageData} alt={""} className="rounded-3xl shadow-2xl max-h-[413px] max-w-[413px]" />
                </div>
                <div className="max-w-[500px]">
                  <div className="text-xl mb-3 font-title-mobile">{aboutPageContent.platformInfoCardOneTitle}</div>
                  <div className="">{aboutPageContent.platformInfoCardOneDescription.data.childMarkdownRemark.rawMarkdownBody}</div>
                </div>

              </div>
              <div className="mb-20 flex flex-col items-center">
                <div className="p-12 md:p-16 bg-[#2F5B92] rounded-3xl mb-8 ">
                  <GatsbyImage image={aboutPageContent.platformInfoCardTwoImage.localFile.childImageSharp.gatsbyImageData} alt={""} className="rounded-3xl shadow-2xl max-h-[413px] max-w-[413px]" />
                </div>
                <div className="max-w-[500px]">
                  <div className="text-xl mb-3 font-title-mobile">{aboutPageContent.platformInfoCardOTwoTitle}</div>
                  <div className="">{aboutPageContent.platformInfoCardTwoDescription.data.childMarkdownRemark.rawMarkdownBody}</div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="mb-20 flex justify-center items-center">
                <div className="max-w-[300px] mr-32">
                  <div className="text-2xl font-title-mobile">{aboutPageContent.platformInfoCardOneTitle}</div>
                  <div className="">{aboutPageContent.platformInfoCardOneDescription.data.childMarkdownRemark.rawMarkdownBody}</div>
                </div>
                <div className="flex justify-center mb-8 p-20 bg-[#2F5B92] rounded-3xl">
                  <GatsbyImage image={aboutPageContent.platformInfoCardOneImage.localFile.childImageSharp.gatsbyImageData} alt={""} className="rounded-3xl max-h-[380px] max-w-[380px]" />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="flex justify-center mb-8 p-20 bg-[#2F5B92] rounded-3xl">
                  <GatsbyImage image={aboutPageContent.platformInfoCardTwoImage.localFile.childImageSharp.gatsbyImageData} alt={""} className="rounded-3xl max-h-[380px] max-w-[380px]" />
                </div>
                <div className="max-w-[300px] ml-32">
                  <div className="text-2xl font-title-mobile">{aboutPageContent.platformInfoCardOTwoTitle}</div>
                  <div className="">{aboutPageContent.platformInfoCardTwoDescription.data.childMarkdownRemark.rawMarkdownBody}</div>
                </div>
              </div>
            </div>
          </div>
          <img src={dividerBottom} className="w-[100vw]"></img>
        </PageContent>

        <PageContent>
          <SectionHeader
            centered
            title={aboutPageContent.dataScienceProductShowcaseTitle}
            description={aboutPageContent.dataScienceProductShowcaseDescription.data.childMarkdownRemark.rawMarkdownBody}
            descriptionClassName="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 gap-x-12 xl:gap-x-16 justify-items-center">
            {dataScienceCards.map((card, i) => {
              return (
                <DataScienceCard key={card.node.title} icon={card.node.cardImage.localFile.publicURL} desc={card.node.description.data.childMarkdownRemark.rawMarkdownBody} title={card.node.title} index={i} />
              );
            })}
          </div>
        </PageContent>
        <PageContent>
          <SectionHeader
            centered
            title={aboutPageContent.caseStudiesTitle.data.childMarkdownRemark.rawMarkdownBody}
            description={aboutPageContent.caseStudiesDescription.data.childMarkdownRemark.rawMarkdownBody}
            descriptionClassName="px-0 w-auto max-w-[650px] pt-3 font-normal mx-auto text-center"
          />
          <CaseStudy />
        </PageContent>
        <PageContent>
          <Footer />
        </PageContent>
      </main>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage {
    aboutPage: allStrapiNorthstarAboutPage {
        edges {
          node {
            heroImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            dataScienceProductShowcaseDescription {
              data {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
              dataScienceProductShowcaseTitle
              heroDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              heroTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpTitle
              howWeHelpLeftColumnTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpMiddleColumnTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpRightColumnTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpLeftColumnItemOne {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpLeftColumnItemTwo {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpLeftColumnItemThree {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpMiddleColumnItemOne {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpMiddleColumnItemTwo {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpMiddleColumnItemThree {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpRightColumnItemOne {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpRightColumnItemTwo {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              howWeHelpRightColumnItemThree {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              platformInfoTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              platformInfoDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              platformInfoCardOTwoTitle
              platformInfoCardOneTitle
              platformInfoCardOneDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              platformInfoCardTwoDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              platformInfoCardOneImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              platformInfoCardTwoImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              caseStudiesTitle {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              caseStudiesDescription {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
            }
          }
        }
        dataScienceCards: allStrapiDataScienceShowcase(sort: {order: ASC, fields: strapi_id}) {
          edges {
            node {
              cardImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  publicURL
                }
              }
              description {
                data {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
              title
            }
          }
        }
    }
`;
